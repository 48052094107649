import {
  Route,
  Routes
} from 'react-router-dom';
import './App.css';
import Home from './components/home/Home';
import SinglePage from './components/singlepage/SinglePage';
import Address from './components/address/Address';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Checkout from './components/checkout/Checkout';
import Payment from './components/payment/Payment';

function App() {

  return (<
          div className="App" >
    <
          Routes >
      <Route path="/" element={< Home />}/> 
      <Route path="/:id" element={<ScrollToTop> < SinglePage /> </ScrollToTop>}/ >
          <
            Route path="/address/:id"
            element={<ScrollToTop > < Address /> </ScrollToTop>}/ >
              <
                Route path="/checkout/:id"
                element={<ScrollToTop > < Checkout /> </ScrollToTop>}/ >
                  <
                    Route path="/payments/:id"
                    element={<ScrollToTop > < Payment /> </ScrollToTop>}/ >




                      </Routes>

                    
                          </div>
                      );
                  }

                      export default App;