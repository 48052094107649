// export const paymentDetails = {
//     gpay:"ombk.aaex34657i2z9uley96@mbk",
//     paytm:"ombk.aaex34657i2z9uley96@mbk",
//     phonepe:"ccpay.6528680495134008@icici",
//     upi:"ccpay.6528680495134008@icici"
// }

// export const paymentDetails = {
//     gpay:"ombk.aaex34657i2z9uley96@mbk",
//     paytm:"ombk.aaex34657i2z9uley96@mbk",
//     phonepe:"ccpay.4035625478314003@icici",
//     upi:"ccpay.4035625478314003@icici"
// }



export const paymentDetails = {
    gpay: "7247876411@okbizaxis",
    paytm: "7247876411@okbizaxis",
    phonepe: "7247876411@okbizaxis",
    upi: "7247876411@okbizaxis"
}


// export const paymentDetails = {
//     gpay:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     paytm:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     phonepe:"ombk.AAEP074961dpa8ev8wjo@mbk",
//     upi:"ombk.AAEP074961dpa8ev8wjo@mbk"
// }